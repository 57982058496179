import "./App.css";
import FurnitureShop from "./components/FurnitureShop";
function App() {
  return (
    <>
      <FurnitureShop />
    </>
  );
}

export default App;
